import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class UserService {
   
    subject$: ReplaySubject<User> = new ReplaySubject<User>(1);
    data$: Observable<User> = this.subject$.asObservable();

    constructor(private http: HttpClient) { 
        this.data$.pipe().subscribe(user => {
            
            console.log('User updated', user);
          });

    }
    
    refreshUser(resultUser: User) {
        console.log('UserService', resultUser)
        //this.subject$.next(resultUser);
        return this.http.post(`${config.apiUrl}/users/new`, resultUser,);
    }

    createUser(resultUser: User) {
        return this.http.post(`${config.apiUrl}/users/new`, resultUser);
    }

    updateUser(user: User) {
        console.log('UserService', user)
        this.subject$.next(user);
        return this.http.post(`${config.apiUrl}/users/update`, user);
    }

    getAll(limit: number, offset: number, event: any, search: any) {
        return this.http.get<User[]>(`${config.apiUrl}/users?limit=${limit}&offset=${offset}&sortBy=${event.active}&sortType=${event.direction}&search=${search}`);
    }

    getAllLocked() {
        return this.http.get<User[]>(`${config.apiUrl}/users/processedusers/all/grantee/locked`);
    }

    getProcessedUsers() {
        return this.http.get<User[]>(`${config.apiUrl}/users/processedusers/all/grantee`);
    }

    getById(id: number) {
        return this.http.get<User>(`${config.apiUrl}/users/${id}`);
    }
    

    runBulkProcess() {
        return this.http.get(`${config.apiUrl}/users/bulkprocess/grantee/all`).pipe(); 
    }

    resetPassword(formObj: {}) {
        return this.http.post(`${config.apiUrl}/users/resetpassword`, formObj).pipe(); 
    }

    forgotpassword(email: string) {
        return this.http.post(`${config.apiUrl}/users/forgotpassword`, email); // /reset_pw/user/:email
    }

    sendResetPassword(emailObj: any) {
        return this.http.post(`${config.apiUrl}/users/sendResetPassword`, emailObj).pipe();
    }

    sendEmail(emailObj: any) {
        return this.http.post(`${config.apiUrl}/users/sendemail`, emailObj);
    }
}